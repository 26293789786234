<mat-drawer-container>
  <mat-drawer-content>
    <!-- NASLOV -->
    <div class="row" style="margin: auto; padding: 25px;">
      <div class="d-none d-sm-block col-sm-3"></div>
      <div class="col-1">
        <img routerLink="/user/dashboard" style="cursor: pointer"
             src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FbackIcon.png?alt=media&token=d3776f59-033a-4d06-b5f3-ba9e925bc495"
             alt="faq page image">
      </div>
      <div class="col-3 col-sm-4" style="margin: auto; text-align: center">
        <h3 class="naslov"
            style="color: #0E0D35;font-family: Lato, 'Alegreya Sans', 'Alegreya Sans'; font-size: 30px; font-weight: bold;">
          Додатна упутства за сертификоване геодетске стручњаке</h3>
      </div>
      <div class="col-9 col-sm-3" style="text-align: right;">
        <!-- <span style = "cursor: pointer; padding-right: 15px;" routerLink = "/user/calendar">
          <img src = "https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webIcons%2FcalendarIcon.png?alt=media&token=0dcbbd8e-d153-4851-af32-658f01446fbd">
        </span> -->

        <span style="cursor: pointer; vertical-align: middle; margin-right: 10px;" routerLink="/user/faq">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/webImgs%2FhomeIcon1.png?alt=media&token=f55962fc-39ce-44f5-a7f2-139e4e71c0ea"
            alt="faq icon">
        </span>

        <button (click)="logout()"
                style="background-color: #f1f1f1; vertical-align: middle; font-family: Lato, 'Alegreya Sans'; color: #0E0D35; font-size: 16px; padding: 7px; border: 1px solid #f1f1f1; border-radius: 8px;">
          <mat-icon style="vertical-align: middle; color: #0E0D35;">exit_to_app</mat-icon>
          <span class="mobHidden">{{ labels.lblLogOut }}</span>
        </button>
      </div>
    </div>
    <div class="coverImageWrap">
      <img class="coverImage" style="width: 100%;"
           src="https://firebasestorage.googleapis.com/v0/b/leste-mobile.appspot.com/o/Naslovna-fotka.png?alt=media&token=0efd5995-03be-43a0-8fb5-5990c9c4c427">
    </div>
    <div class="custom-accordion">
      <div class="card">
        <h1>Додатна упутства за сертификоване геодетске стручњаке</h1>

        <details class="info">
          <summary>Упутство за
            инсталацију Модула за геодетске организације
          </summary>
          <div class="accordion-item">
            <span>Упутство за инсталацију Модула за геодетске организације</span>
            <a class="download_button"
               href="/assets/Uputstvo1/Uputstvo%20za%20instalaciju%20MGO_v.2.pdf" download><span>Преузми</span><span>PDF</span></a>
          </div>
          <div class="accordion-item">
            <span>Инсталациони прилог "cacerts"</span>
            <a class="download_button"
               href="/assets/Uputstvo1/cacerts.rar" download><span>Преузми</span><span>rar</span></a>
          </div>
        </details>

        <details class="info">
          <summary>Упутство за рад у Модулу за геодетске организације (МГО)</summary>
          <div class="accordion-item">
            <div class="custom-accordion">
              <div class="card">
                <details class="info">
                  <summary>Општа упутства:</summary>
                  <div class="accordion-item">
                    <span>Упутство МГО ажурирано 27092024 </span>
                    <a class="download_button" href="/assets/Uputstvo2/Uputstvo%20MGO_ažurirano%2027092024.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Упутство МГО УВОД </span>
                    <a class="download_button" href="/assets/Uputstvo2/Uputstvo%20MGO_UVOD.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Издавање КН података-Реализација пројекта парцелације-препарцелације </span>
                    <a class="download_button" href="/assets/Uputstvo2/Издавање%20КН%20података-Реализација%20пројекта%20парцелације-препарцелације.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Рад у МГО-у у КО у којима су подаци катастра непокретности трансформисани из стереографске у УТМ пројекцију </span>
                    <a class="download_button" href="/assets/Uputstvo2/Рад%20у%20МГО-у%20у%20КО%20у%20којима%20су%20подаци%20катастра%20непокретности%20трансформисани%20из%20стереографске%20у%20УТМ%20пројекцију.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span> Подешавање Скице старо-ново стање у МГО-у</span>
                    <a class="download_button" href="/assets/Uputstvo2/Подешавање%20Скице%20старо-ново%20стање%20у%20МГО-у.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Упутство за поступање код поруке EЛАБОРАТ СЕ ВЕЋ УРЕЂУЈЕ. АПЛИКАЦИЈА СЕ НЕЋЕ ПОКРЕНУТИ </span>
                    <a class="download_button" href="/assets/Uputstvo2/Упутство%20за%20поступање%20код%20поруке%20EЛАБОРАТ%20СЕ%20ВЕЋ%20УРЕЂУЈЕ.%20АПЛИКАЦИЈА%20СЕ%20НЕЋЕ%20ПОКРЕНУТИ.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>
                </details>
              </div>
            </div>
          </div>

          <div class="accordion-item">
            <div class="custom-accordion">
              <div class="card">
                <details class="info">
                  <summary>Рад са предметима DQI:</summary>
                  <div class="accordion-item">
                    <span>Упутство за рад у Едитору МГО-а са предметима из ДQI-а</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/Upustvo%20za%20rad%20u%20Editoru%20MGO-a%20sa%20predmetima%20iz%20DQI-a.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Атрибути и шифарници тачака</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Atributi%20i%20sifarnici%20tacaka.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Атрибути линија култура</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Atributi%20linija%20kultura.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Деоба парцеле са културама и уређење њихових површина(ЈР)</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Deoba%20parcele%20sa%20kulturama%20i%20uređenje%20njihovih%20površina%20(JP).pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Грешка - не постоји део парцеле</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Greska%20ne%20postoji%20deo%20parcele.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Израда ДГЕ-без графичке промене у КN</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Izrada%20DGE-bez%20graficke%20promene%20u%20KN.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Контроле разлика службене површине</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Kontrole_Razlika%20službene%20površine.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Креирање и увоз CSV фајла</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Kreiranje%20i%20uvoz%20csv%20fajla.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Креирање суперсертификата објекат парцела</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Kreranje%20supertifikata_Objekat%20parcela.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Спајање парцела различитих култура са уређивањем површина(ЈР)</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Spajanje%20parcela%20razlicitih%20kultura%20sa%20uredjivanjem%20površina%20(JP).pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Завршетак рада у едитору</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/DQI-Zavrsetak%20rada%20u%20Editoru.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  </details>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <div class="custom-accordion">
              <div class="card">
                <details class="info">
                  <summary>Рад са предметима ИСКН:</summary>
                  <div class="accordion-item">
                    <span>Упутство за рад у Едитору МГО-а са предметима из ИСКН-а </span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/Upustvo%20za%20rad%20u%20Editoru%20MGO-a%20sa%20predmetima%20iz%20ISKN-a.pdf"
                       download><span>Преузми</span><span>PDF</span></a>
                  </div>

                  <div class="accordion-item">
                    <span>Упутство за нумерацију граничких тачака у МГО-у</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/Uputstvo%20za%20numeraciju%20graničkih%20tačaka%20u%20MGO-u.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Оправдање предбележбе објекта са исправком граница објекта</span>
                    <a class="download_button"
                       href="/assets/Uputstvo3/Оправдање%20предбележбе%20објекта%20са%20исправком%20граница%20објекта.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Промена габарита објекта у који се налази више парцека</span>
                    <a class="download_button"
                       href="/assets/Uputstvo3/Промена%20габарита%20објекта%20који%20се%20налази%20на%20више%20парцела.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Упис објекта на парцели са више различитих култура</span>
                    <a class="download_button"
                       href="/assets/Uputstvo3/Упис%20објекта%20на%20парцели%20са%20више%20различитих%20култура.pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Упутство за креирање CSV fajl-a (koordinate)</span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/Uputstvo%20za%20kreiranje%20CSV%20fajl-a%20(koordinate).pdf" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                  <div class="accordion-item">
                    <span>Template CSV-a </span>
                    <a class="download_button"
                       href="/assets/Uputstvo2/Template%20csv-a%20(koordinate).csv" download><span>Преузми</span><span>PDF</span></a>
                  </div>
                </details>
              </div>
            </div>
          </div>
        </details>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
